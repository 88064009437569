// src/constants.ts
var COOKIE_NAMES = {
  PLAYER_IDENTIFIER: "playerIdentifier",
  ACCESS_TOKEN: "accessToken"
};
var DEFAULT_BOARD_SIZE = 4;
var DRAW = "DRAW";
var CLIENT_SIGNALS = {
  join: "join",
  joinPrivateGame: "joinPrivateGame",
  disconnect: "disconnect",
  move: "move",
  playAgain: "playAgain"
};
var SERVER_SIGNALS = {
  gameCountdown: "gameCountdown",
  startGame: "startGame",
  boardUpdate: "boardUpdate",
  endGame: "endGame",
  joinLobby: "joinLobby",
  joinPrivateLobby: "joinPrivateLobby"
};
var DIRECTIONS = {
  UP: "UP",
  DOWN: "DOWN",
  LEFT: "LEFT",
  RIGHT: "RIGHT"
};
var OPERATIONS = {
  RESET: "RESET"
};
var MOVES = {
  ...DIRECTIONS,
  ...OPERATIONS
};

// src/utils.ts
function chunk(array, size) {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArray.push(array.slice(i, i + size));
  }
  return chunkedArray;
}
var deepCopyArray = (array) => JSON.parse(JSON.stringify(array));
var sum = (array) => array.reduce((acc, x) => acc + x, 0);
function arrayDifferenceLeft(arr1, arr2) {
  return arr1.filter((item) => !arr2.includes(item));
}
function addTimeToCurrentTimestamp(ms) {
  const now = /* @__PURE__ */ new Date();
  const later = new Date(now.getTime() + ms);
  return later.toISOString();
}
function areArraysEqual(array1, array2) {
  return JSON.stringify(array1) === JSON.stringify(array2);
}
async function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// src/Board/boardUtils.ts
function createZeroMatrix(size) {
  return Array.from({ length: size }, () => Array(size).fill(0));
}
var getTileValue = () => Math.random() > 0.1 ? 2 : 4;
function containsEmpty(board) {
  return board.some((row) => row.some((cell) => cell === 0));
}
function spawnTile(tileGrid) {
  const emptyCoordinates = tileGrid.flatMap((row, y) => {
    return row.flatMap((cell, x) => cell === 0 ? [{ x, y }] : []);
  });
  if (!emptyCoordinates.length) {
    return false;
  }
  const coordinates = emptyCoordinates[Math.floor(Math.random() * emptyCoordinates.length)];
  tileGrid[coordinates.y][coordinates.x] = getTileValue();
  return true;
}
function initializeBoard(boardSize) {
  const board = createZeroMatrix(boardSize);
  spawnTile(board);
  spawnTile(board);
  return board;
}
function rotateRight(matrix) {
  for (let i = 0; i < matrix.length; i++) {
    for (let j = i + 1; j < matrix[i].length; j++) {
      const temp = matrix[i][j];
      matrix[i][j] = matrix[j][i];
      matrix[j][i] = temp;
    }
  }
  for (let i = 0; i < matrix.length; i++) {
    matrix[i].reverse();
  }
}
function rotateBoardToLeft(board, direction, reverse = false) {
  if (direction === "LEFT") {
    return board;
  }
  if (reverse) {
    if (direction === "RIGHT") direction = "LEFT";
    else if (direction === "UP") direction = "DOWN";
    else if (direction === "DOWN") direction = "UP";
  }
  switch (direction) {
    case "RIGHT":
    case "LEFT":
      rotateRight(board);
      rotateRight(board);
      break;
    case "UP":
      rotateRight(board);
      rotateRight(board);
      rotateRight(board);
      break;
    case "DOWN":
      rotateRight(board);
      break;
  }
  return board;
}
function shiftTilesLeftInPlace(array) {
  let count = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== 0) {
      array[count++] = array[i];
    }
  }
  while (count < array.length) {
    array[count++] = 0;
  }
  return array;
}
function combineToLeft(row) {
  let scoreIncrease = 0;
  for (let i = 0; i < row.length - 1; i++) {
    if (row[i] !== 0) {
      const areTileValuesTheSame = row[i] === row[i + 1];
      if (areTileValuesTheSame) {
        const combinedValue = row[i] * 2;
        row[i] = combinedValue;
        row[i + 1] = 0;
        i++;
        scoreIncrease += combinedValue;
      }
    }
  }
  return { row, scoreIncrease };
}
function combineAndShiftLeft(array) {
  shiftTilesLeftInPlace(array);
  const scoreIncrease = combineToLeft(array);
  shiftTilesLeftInPlace(array);
  return scoreIncrease;
}
function slideTiles(tileGrid, direction) {
  let scoreIncrease = 0;
  tileGrid = rotateBoardToLeft(tileGrid, direction);
  for (const row of tileGrid) {
    const { scoreIncrease: rowScoreIncrease } = combineAndShiftLeft(row);
    scoreIncrease += rowScoreIncrease;
  }
  tileGrid = rotateBoardToLeft(tileGrid, direction, true);
  return { tileGrid, scoreIncrease };
}
function checkHorizontalMergePossible(board) {
  for (let rowIndex = 0; rowIndex < board.length; rowIndex++) {
    const row = board[rowIndex];
    for (let tileIndex = 0; tileIndex < row.length - 1; tileIndex++) {
      if (row[tileIndex] === row[tileIndex + 1]) {
        return true;
      }
    }
  }
  return false;
}
function movePossible(board) {
  if (containsEmpty(board)) {
    return true;
  }
  if (checkHorizontalMergePossible(board)) {
    return true;
  }
  const boardCopy = deepCopyArray(board);
  rotateRight(boardCopy);
  if (checkHorizontalMergePossible(boardCopy)) {
    return true;
  }
  return false;
}
function encodeTileGridState(tileGrid, previousMove) {
  return JSON.stringify({ tileGrid, previousMove });
}
function decodeTileGridState(tileGridStateString) {
  return JSON.parse(tileGridStateString);
}

// src/Board/Board.ts
function processMove(tileGrid, direction) {
  const oldTileGrid = deepCopyArray(tileGrid);
  let scoreIncrease = 0;
  let directionValid = true;
  const slideResult = slideTiles(deepCopyArray(tileGrid), direction);
  scoreIncrease = slideResult.scoreIncrease;
  const newTileGrid = slideResult.tileGrid;
  if (areArraysEqual(oldTileGrid, newTileGrid)) {
    directionValid = false;
    return {
      newTileGrid: oldTileGrid,
      scoreIncrease: 0,
      directionValid
    };
  }
  return {
    newTileGrid,
    scoreIncrease,
    directionValid
  };
}
var Board = class {
  playerId;
  score;
  tileGrid;
  nextMovePossible;
  previousMove;
  constructor(playerId) {
    this.playerId = playerId;
    this.nextMovePossible = true;
    this.tileGrid = initializeBoard(DEFAULT_BOARD_SIZE);
    this.score = 0;
  }
  get data() {
    return {
      playerId: this.playerId,
      score: this.score,
      tileGridStateEncoded: encodeTileGridState(this.tileGrid, this.previousMove)
    };
  }
  handleMove(move) {
    if (!this.nextMovePossible) {
      return { directionValid: false };
    }
    const { directionValid, scoreIncrease, newTileGrid } = processMove(this.tileGrid, move);
    if (!directionValid) {
      return { directionValid };
    }
    this.previousMove = move;
    this.score += scoreIncrease;
    this.tileGrid = newTileGrid;
    spawnTile(this.tileGrid);
    this.nextMovePossible = movePossible(this.tileGrid);
    return { directionValid };
  }
  reset() {
    this.previousMove = OPERATIONS.RESET;
    this.nextMovePossible = true;
    this.tileGrid = initializeBoard(DEFAULT_BOARD_SIZE);
    this.score = 0;
  }
};

// src/types.ts
function isStartGamePayload(payload) {
  return typeof payload.endGameTimestamp === "string" && payload.status === "active";
}
export {
  Board,
  CLIENT_SIGNALS,
  COOKIE_NAMES,
  DEFAULT_BOARD_SIZE,
  DIRECTIONS,
  DRAW,
  MOVES,
  OPERATIONS,
  SERVER_SIGNALS,
  addTimeToCurrentTimestamp,
  areArraysEqual,
  arrayDifferenceLeft,
  chunk,
  combineToLeft,
  containsEmpty,
  decodeTileGridState,
  deepCopyArray,
  encodeTileGridState,
  initializeBoard,
  isStartGamePayload,
  movePossible,
  processMove,
  rotateBoardToLeft,
  rotateRight,
  shiftTilesLeftInPlace,
  slideTiles,
  spawnTile,
  sum,
  wait
};
